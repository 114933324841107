<template>
    <div class="c-notifications">
        <transition-group
            name="t-fade"
            tag="span"
        >
            <notification
                v-for="notification in notifications"
                :key="notification.id"
                :notification="notification"
                @close-notification="removeNotification"
            ></notification>
        </transition-group>
    </div>
</template>

<script>
import Notification from '@js/vue-components/general/notification/notification.vue';

export default {
    name: 'Notifications',
    components: {
        Notification,
    },
    data() {
        return {
            notifications: window.NotificationStore.state,
        };
    },
    methods: {
        removeNotification(notification) {
            window.NotificationStore.removeNotification(notification);
        },
    },
};

</script>

<style lang="scss" scoped>
@import "@sass/variables";
@import "@sass/mixins";

.c-notifications {
    max-height: calc(100vh - 40px);
    overflow: auto;
    padding-right: 10px;
    position: fixed;
    right: 15px;
    top: 15px;
    width: 350px;
    z-index: z("notification");

}

</style>
