<template>
    <div
        class="c-notification"
        :class="'c-notification--' + (notification.type ? notification.type : 'secondary')"
    >
        <button
            v-if="hasClose"
            class="c-notification__close u-hover--grow"
            aria-label="Close alert"
            type="button"
            @click="triggerClose"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        <div
            v-if="notification.title"
            class="c-notification__title"
        >
            {{ notification.title }}
        </div>
        <component
            v-if="isComponent"
            :is="notification.payload.name"
            v-bind="notification.payload.props"
            class="c-notification__content"
            @closeNotification="triggerClose"
        >
        </component>
        <div
            v-else-if="notification.html"
            v-dompurify-html="notification.payload"
            class="c-notification__content"
        >
        </div>
        <div
            v-else
            class="c-notification__content"
            v-text="notification.payload"
        >
        </div>
    </div>
</template>

<script>

export default {
    name: 'Notification',
    emits: ['close-notification'],
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    data() {
        return { timer: null };
    },
    computed: {
        isComponent() {
            return _.isObject(this.notification.payload);
        },
        hasClose() {
            return !this.notification.payload?.noClose;
        },
    },
    methods: {
        triggerClose: function () {
            clearTimeout(this.timer);
            this.$emit('close-notification', this.notification);
        },
    },
    mounted() {
        let timeout = this.notification.hasOwnProperty('timeout') ? this.notification.timeout : true;
        if (timeout) {
            let delay = this.notification.delay || 3000;
            this.timer = setTimeout(() => {
                this.triggerClose(this.notification);
            }, delay);
        }
    },
};

</script>

<style lang="scss" scoped>
@import "@sass/mixins";
@import "@sass/variables";

.c-notification {
    color: #000;
    font-size: 15px;
    padding: 15px;
    position: relative;

    &--alert {
        background: $color__alert;
    }

    &--success {
        background: $color__success;
    }

    &--primary {
        background: $color__primary;
    }

    &--secondary {
        background: $color__secondary;
    }

    &--dataenrichment {
        background: $color__dataenrichment;
    }

    &__close {
        position: absolute;
        right: 5px;
        top: 0;
    }

    &__content {
        word-break: break-word;
    }
}

</style>
